import React, { useEffect, useState, forwardRef } from "react";
import { Animated, Platform } from "react-native";
import { Input, Box } from "native-base";

const FloatingLabelInput = forwardRef((props, ref) => {
  const { label, labelColor, labelBGColor, defaultValue, containerWidth } =
    props;
  const _animatedIsFocused = new Animated.Value(defaultValue === "" ? 0 : 1);
  const labelContainerStyles = {
    position: "absolute",
    left: 16,
    top: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [12, -7],
    }),
    zIndex: 5,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: labelBGColor,
  };
  const labelStyle = {
    fontWeight: "500",
    fontSize: _animatedIsFocused.interpolate({
      inputRange: [0, 1],
      outputRange: [14, 12],
    }),
    color: labelColor,
    ...(Platform.OS === "ios"
      ? {
          marginTop: _animatedIsFocused.interpolate({
            inputRange: [0, 1],
            outputRange: [-3, 0],
          }),
        }
      : {}),
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  useEffect(() => {
    Animated.timing(_animatedIsFocused, {
      duration: 200,
      useNativeDriver: false,
      toValue: isFocused || defaultValue !== "" ? 1 : 0,
    }).start();
  }, [isFocused, defaultValue]);

  return (
    <Box w={containerWidth}>
      <Animated.View pointerEvents="none" style={labelContainerStyles}>
        <Animated.Text style={labelStyle}>{label}</Animated.Text>
      </Animated.View>
      <Input
        {...props}
        ref={ref} // <-- Forward the ref here
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        _hover={{ bg: labelBGColor }}
      />
    </Box>
  );
});

export default FloatingLabelInput;
